@import 'vars'

@font-face {
  font-family Inter
  font-style normal
  font-weight 300
  font-display optional
  src url('@src/base/fonts/Inter-Light.woff2') format('woff2')
}

@font-face {
  font-family Inter
  font-style normal
  font-weight 700
  font-display optional
  src url('@src/base/fonts/Inter-Bold.woff2') format('woff2')
}

:root {
  font-family Inter, sans-serif
  font-feature-settings 'liga' 1, 'calt' 1; /* fix for Chrome */
}

html,
body
  margin 0
  color var(--vcolor-darkest)
  font-size 16px
  font-weight 300

h1, h2, h3, h4, h5, h6
  margin 0

p
  margin 0
  line-height 1.2

input,
select
  padding 9px
  min-width 150px
  min-width 43.5px
  background-color var(--vcolor-grey-light)
  color var(--vcolor-grey-darker)
  font-family var(--vfont-family-primary)
  font-size var(--vfont-size-sm)
  border-width 2px
  border-style solid
  border-color var(--vcolor-grey-light)
  border-radius 6px
  box-sizing border-box
  transition-property background-color, border-color
  transition-duration 200ms
  transition-timing-function ease-in-out
  outline 0
  -webkit-appearance none
  &:focus,
  &:active
    background-color var(--vcolor-lightest)
    border-color var(--vcolor-red-light)

select
  position relative
  padding 9px 30px 9px 9px
  background-color var(--vcolor-grey-light)
  background-image url('@src/base/images/icon-arrows_40x40.svg?v=1')
  background-position right center
  background-repeat no-repeat

mark
  color var(--vcolor-red-darker)
  background-color var(--vcolor-red-light)
